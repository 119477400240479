import { Component, Vue, Ref } from "vue-property-decorator";
import API from "@api";
import {
  formatDate,
  getSymbolCurrency,
  formatMoney,
  nestedFreeze,
} from "@helpers";
import {
  profileModule,
  userModule,
  teamModule,
  walletModule,
} from "@store/namespaces";
import {
  Transaction,
  TransactionCardType,
  TransactionStatus,
  TransactionType,
} from "@/types/transaction";

import { formatCardNumber } from "@/lib/creaditCard";
import {
  formatDatePickerVal,
  parseDatePickerVal,
  periodRangeEndTimestamp,
  periodRangeStartTimestamp,
  getTimezoneHoursInSeconds,
  getPeriodDate,
} from "@/lib/date";
import { ProfileGetters } from "@store/modules/profile/types";
import { Role } from "@/types/role";

import { Card, CardGroup } from "@/types/card";
import { UserActions, UserGetters } from "@store/modules/user/types";
import { UserWebPreferencesKey } from "@/types/user";
import debounce from "lodash.debounce";
import {
  TableHeaderValue,
  TopBarFilter,
  TopBarFilterItems,
} from "@/types/components/filter-table-top-bar";
import { DataTableHeader } from "vuetify";
import { Currency } from "@/types/currency";
import {
  transactionCardTypes,
  transactionStatuses,
  transactionTypes,
} from "@config/transaction";
import { Period } from "@/types/datepicker";
import { TeamGetters, TeamActions } from "@store/modules/team/types";
import { WalletGetters } from "@store/modules/wallet/types";
import { HtmlElementId } from "@/types/element";
import { StepAlias } from "@/types/productTour";
import mockTransactions from "@/mockData/transactions";
import { MultiSelectInstance } from "@/types/components/multiselect";

interface Filter {
  forceUpdateKey: number;
  page: number;
  sort: string[];
  itemsPerPage: number;
  periodStart: number;
  periodEnd: number;
  cardIds: number[];
  cardGroupIds: (number | null)[];
  teamMembers: {
    teamleadEmails: string[];
    mediabuyerEmails: string[];
    otherEmails: string[];
  };
}

type TopBarTableFilter = TopBarFilter<"status", TransactionStatus> &
  TopBarFilter<"type", TransactionType> &
  TopBarFilter<"cardType", TransactionCardType> &
  TopBarFilter<"currency", Currency>;

@Component
export default class TransactionsMixin extends Vue {
  @Ref("cardGroups") private readonly cardGroupsRef?: MultiSelectInstance;
  @Ref("cards") private readonly cardsRef?: MultiSelectInstance;
  @Ref("mediabuyers") private readonly mediabuyersRef?: MultiSelectInstance;
  @Ref("teamleads") private readonly teamleadsRef?: MultiSelectInstance;
  @Ref("teamMembers") private readonly teamMembersRef?: MultiSelectInstance;

  @teamModule.Action("fetchTeamMembers")
  private readonly fetchTeamMembersAction!: TeamActions["fetchTeamMembers"];
  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @profileModule.Getter("profileTeamleadEmail")
  private readonly profileTeamleadEmailGetter!: ProfileGetters["profileTeamleadEmail"];
  @userModule.Action("fetchUserWebPreferences")
  private readonly fetchUserWebPreferencesAction!: UserActions["fetchUserWebPreferences"];
  @userModule.Action("updateUserWebPreferences")
  private readonly updateUserWebPreferencesAction!: UserActions["updateUserWebPreferences"];
  @userModule.Getter("userWebPreferences")
  private readonly userWebPreferencesGetter!: UserGetters["userWebPreferences"];
  @teamModule.Getter("teamleadMembers")
  private readonly teamleadMembersGetter!: TeamGetters["teamleadMembers"];
  @teamModule.Getter("mediabuyerMembers")
  private readonly mediabuyerMembersGetter!: TeamGetters["mediabuyerMembers"];
  @teamModule.Getter("members")
  private readonly membersGetter!: TeamGetters["members"];
  @teamModule.Getter("membersLoading")
  private readonly membersLoadingGetter!: TeamGetters["membersLoading"];
  @walletModule.Getter("walletsCurrencies")
  private readonly walletsCurrenciesGetter!: WalletGetters["walletsCurrencies"];

  private totalTransactions = 0;
  private transactions: Transaction[] = [];
  private forceUpdateUserWebPreferencesKey = Date.now();
  private loadingTransactions = true;
  private topBarTableHeaderValue: Readonly<TableHeaderValue> = [];
  private searchStr = "";

  private awaitDeps = ["cards", "cardGroups"];

  private includeCardIds: number[] = [];
  private cardsList: Readonly<Pick<Card, "id" | "owner">[]> = [];
  private cardGroupIds: (number | null)[] = [];
  protected filter: Filter = {
    page: 1,
    sort: [],
    itemsPerPage: 15,
    forceUpdateKey: Date.now(),
    periodStart: 0,
    periodEnd: 0,
    cardIds: [],
    cardGroupIds: [],
    teamMembers: {
      teamleadEmails: [],
      mediabuyerEmails: [],
      otherEmails: [],
    },
  };

  private topBarTableFilter: TopBarTableFilter = {
    currency: [],
    status: transactionStatuses.map((value) =>
      Object.freeze({
        selected: true,
        value,
      })
    ),
    type: transactionTypes.map((value) =>
      Object.freeze({
        selected: true,
        value,
      })
    ),
    cardType: transactionCardTypes.map((value) =>
      Object.freeze({
        selected: true,
        value,
      })
    ),
  };

  private get usedInfiniteTransactionsList() {
    return this.$vuetify.breakpoint.mobile;
  }

  private get itemsPerPage() {
    if (this.usedInfiniteTransactionsList) {
      return 10;
    }

    return this.filter.itemsPerPage;
  }

  private get isShowedMainIntro() {
    return this.$productTour.activeIntro.type === "product-tour";
  }

  private get htmlElementId() {
    return {
      transactionsFiltersContainerId:
        HtmlElementId.transactionsFiltersContainerId,
      transactionsFiltersButtonId: HtmlElementId.transactionsFiltersButtonId,
      transactionsDownloadFilesContainerId:
        HtmlElementId.transactionsDownloadFilesContainerId,
      transactionsTable: HtmlElementId.transactionsTable,
    };
  }

  protected get loadingTransactionList(): boolean {
    return this.loadingTransactions || this.membersLoadingGetter;
  }

  private get userEmails() {
    if (this.isShowTeamMemberSelect) {
      return this.filter.teamMembers.otherEmails;
    }

    return [
      ...this.filter.teamMembers.teamleadEmails,
      ...this.filter.teamMembers.mediabuyerEmails,
    ];
  }

  protected get rangeDate(): string[] {
    const range: string[] = [];

    if (this.filter.periodStart) {
      range.push(formatDatePickerVal(this.filter.periodStart * 1000));
    }

    if (this.filter.periodEnd) {
      range.push(formatDatePickerVal(this.filter.periodEnd * 1000));
    }

    return range;
  }

  protected set rangeDate(val: string[]) {
    const dateStart = val[0] ? parseDatePickerVal(val[0]) : null;
    const dateEnd = val[1] ? parseDatePickerVal(val[1]) : null;

    if (dateStart) {
      this.filter.periodStart = periodRangeStartTimestamp(dateStart);
    } else {
      this.filter.periodStart = 0;
    }

    if (dateEnd) {
      this.filter.periodEnd = periodRangeEndTimestamp(dateEnd);
    } else {
      this.filter.periodEnd = 0;
    }
  }

  private async getDownloadedData() {
    const statuses = this.topBarTableFilter.status.reduce<TransactionStatus[]>(
      (acc, { value, selected }) => {
        if (selected) {
          acc.push(value);
        }

        return acc;
      },
      []
    );

    const currencies = this.topBarTableFilter.currency.reduce<Currency[]>(
      (acc, { value, selected }) => {
        if (selected) {
          acc.push(value);
        }

        return acc;
      },
      []
    );

    const types = this.topBarTableFilter.type.reduce<TransactionType[]>(
      (acc, { value, selected }) => {
        if (selected) {
          acc.push(value);
        }

        return acc;
      },
      []
    );

    const sort = this.filter.sort;

    const { items } = await API.transaction.fetchTransactions({
      sort,
      statuses,
      currencies,
      types,
      periodStart: this.filter.periodStart + getTimezoneHoursInSeconds(),
      periodEnd: this.filter.periodEnd + getTimezoneHoursInSeconds(),
      cardIds: this.filter.cardIds,
      userEmails: this.userEmails,
      cardGroupIds: this.filter.cardGroupIds,
      receiptIds: this.receptIdFromSearch
        ? [this.receptIdFromSearch]
        : undefined,
    });

    return {
      items: this.formatItems(items).map((item) => ({
        ...item,
        date: formatDate(item.date, { showTime: true }),
        currency: getSymbolCurrency(item.currency),
        status: this.$vuetify.lang.t(
          `$vuetify.dashboard.transaction.status.${item.status.toLowerCase()}`
        ),
        amount: formatMoney({
          value: item.amount,
          currency: item.currency,
          showSymbol: false,
        }),
        totalTransactionFee: formatMoney({
          value: item.totalTransactionFee,
          currency: item.currency,
          showSymbol: false,
        }),
      })),
      headers: this.headers
        .filter((header) => !!header.value)
        .reduce<Record<string, string>>((acc, { value, text }) => {
          acc[value] = text;

          return acc;
        }, {}),
    };
  }

  private get excludeTopBarHeaders(): string[] {
    return ["id"];
  }

  private get filterFields() {
    const countAvailableCardTypes = transactionCardTypes.reduce<number>(
      (sum, cardType) => {
        sum += +this.transactions.some(
          (transaction) => transaction.cardType === cardType
        );

        return sum;
      },
      0
    );

    return [
      countAvailableCardTypes > 1 && {
        title: this.$vuetify.lang.t("$vuetify.dashboard.table.header.cardType"),
        type: "checkbox",
        name: "cardType",
        items: transactionCardTypes.map((value) => ({
          text: this.$vuetify.lang.t(
            `$vuetify.dashboard.transaction.cardType.${value.toLowerCase()}`
          ),
          value,
        })),
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.table.header.currency"),
        type: "checkbox",
        name: "currency",
        items: this.walletsCurrenciesGetter.map((value) => ({
          text: getSymbolCurrency(value),
          value,
        })),
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.table.header.status"),
        type: "checkbox",
        name: "status",
        items: transactionStatuses.map((value) => ({
          text: this.$vuetify.lang.t(
            `$vuetify.dashboard.transaction.status.${value.toLowerCase()}`
          ),
          value,
        })),
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.table.header.type"),
        type: "checkbox",
        name: "type",
        items: transactionTypes.map((value) => ({
          text: this.getTransactionTypeLabel(value),
          value,
        })),
      },
    ].filter(Boolean);
  }

  private get headers() {
    const headers: DataTableHeader[] = [
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Receipt ID",
        value: "recieptId",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.date"),
        value: "date",
        align: "center",
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.status"),
        value: "status",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.amount"),
        value: "amount",
        align: "center",
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.currency"),
        value: "currency",
        align: "center",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.commission_rate"
        ),
        value: "totalTransactionFee",
        align: "center",
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.type"),
        value: "type",
        align: "center",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.description"
        ),
        value: "description",
        align: "center",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.card_number"
        ),
        value: "cardNumber",
        align: "center",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.account"),
        value: "wallet",
        align: "center",
        sortable: false,
      },
    ];

    return headers;
  }

  private get topBarTableHeaderItems() {
    const items = this.headers.filter(
      ({ value }) => !this.excludeTopBarHeaders.includes(value)
    );

    return items;
  }

  private get tableHeaders() {
    const topBarTableHeaderValue = this.topBarTableHeaderValue;

    return this.headers.filter(
      ({ value }) =>
        this.excludeTopBarHeaders.includes(value) ||
        topBarTableHeaderValue.some(
          ({ columnName, selected }) => columnName === value && selected
        )
    );
  }

  private get receptIdFromSearch() {
    const label = "Recept ID: ";

    if (!this.searchStr.includes(label)) {
      return "";
    }

    return this.searchStr.trim().replace(label, "");
  }

  private get items() {
    const usedMockData =
      this.isShowedMainIntro &&
      this.$productTour.activeIntro.step.alias ===
        StepAlias.MOCK_TRANSACTIONS_LIST;

    const items = usedMockData ? mockTransactions : this.transactions;

    return this.formatItems(items);
  }

  private get isShowTeamMemberSelect() {
    return (
      this.userHasRoleGetter(Role.ROLE_TEAMLEAD) &&
      !this.isShowTeamleadSelect &&
      !this.isShowMediabuyerSelect
    );
  }

  private get isShowTeamleadSelect() {
    return this.userHasRoleGetter([Role.ROLE_OWNER, Role.ROLE_ACCOUNTANT]);
  }

  private get isShowMediabuyerSelect() {
    return this.userHasRoleGetter([Role.ROLE_OWNER, Role.ROLE_ACCOUNTANT]);
  }

  protected get disabledSearch(): boolean {
    const rangeDay = Math.ceil(
      (this.filter.periodEnd - this.filter.periodStart) / (60 * 60 * 24)
    );

    return !rangeDay || rangeDay < 0 || rangeDay > 7 * 30;
  }

  private onReadyAwaitDeps(
    dependency:
      | {
          key: "cards";
          data: Card[];
        }
      | {
          key: "cardGroups";
          data: CardGroup[];
        }
  ) {
    this.awaitDeps = this.awaitDeps.filter((name) => name !== dependency.key);

    switch (dependency.key) {
      case "cards":
        {
          this.cardsList = dependency.data.map(({ id, owner }) =>
            Object.freeze({
              owner,
              id,
            })
          );
        }

        break;

      case "cardGroups":
        {
          this.cardGroupIds = dependency.data.map(({ id }) => id);
        }

        break;

      default:
        break;
    }
  }

  private formatItems(items: Transaction[]) {
    return items.map((item) => {
      const typeIcon = {
        [TransactionType.AUTHORIZATION]: "$accountCircle",
        [TransactionType.COMMISSION]: "$pieChartFilled",
        [TransactionType.PAYMENT]: "$wallet",
        [TransactionType.REFILL]: "$money",
        [TransactionType.REFUND]: "$undo",
        [TransactionType.UNKNOWN]: "$creditCardB",
      }[item.type];

      const typeColor = {
        [TransactionType.AUTHORIZATION]: "#ACB8C9",
        [TransactionType.COMMISSION]: "#FFC008",
        [TransactionType.PAYMENT]: "#ACB8C9",
        [TransactionType.REFILL]: "#4E96FA",
        [TransactionType.REFUND]: "#4E96FA",
        [TransactionType.UNKNOWN]: "#CC83E5",
      }[item.type];

      const typeCode = item.type;

      return Object.freeze({
        ...item,
        typeCode,
        typeIcon,
        typeColor,
        type: this.getTransactionTypeLabel(item.type),
        date: new Date(item.createdAt).getTime(),
        totalTransactionFee: item.transactionFee + item.transactionFixedFee,
        cardNumber: formatCardNumber(item.cardNumber),
      });
    });
  }

  protected async fetchAllTransactions() {
    this.teamMembersRef?.selectAll();
    this.mediabuyersRef?.selectAll();
    this.teamleadsRef?.selectAll();
    this.cardGroupsRef?.selectAll();
    this.cardsRef?.selectAll();

    await this.$nextTick();
    this.refreshTransactions();
  }

  protected refreshTransactions(): void {
    if (!this.filter.teamMembers.otherEmails.length) {
      this.filter.teamMembers.otherEmails = [this.profileEmailGetter];
    }

    if (
      !this.filter.teamMembers.teamleadEmails.length &&
      !this.filter.teamMembers.mediabuyerEmails.length
    ) {
      this.filter.teamMembers.teamleadEmails = this.teamleadMembersGetter
        .map(({ email }) => email)
        .concat([this.profileEmailGetter]);
      this.filter.teamMembers.mediabuyerEmails =
        this.mediabuyerMembersGetter.map(({ email }) => email);
    }

    if (this.usedInfiniteTransactionsList) {
      this.transactions = [];
      this.filter.page = 1;
    }

    this.filter.forceUpdateKey++;
  }

  private onChangeTopBarTableFilter(val: TopBarTableFilter) {
    this.topBarTableFilter = val;
  }

  private onChangeTableHeaderValue(value: TableHeaderValue) {
    this.topBarTableHeaderValue = value;
  }

  private getTransactionTypeLabel(type: TransactionType) {
    return this.$vuetify.lang.t(
      `$vuetify.dashboard.transaction.type.${type.toLowerCase()}`
    );
  }

  private async fetchTransactions() {
    if (this.loadingTransactions) return;

    this.loadingTransactions = true;

    try {
      const statuses = this.topBarTableFilter.status.reduce<
        TransactionStatus[]
      >((acc, { value, selected }) => {
        if (selected) {
          acc.push(value);
        }

        return acc;
      }, []);

      const currencies = this.topBarTableFilter.currency.reduce<Currency[]>(
        (acc, { value, selected }) => {
          if (selected) {
            acc.push(value);
          }

          return acc;
        },
        []
      );

      const types = this.topBarTableFilter.type.reduce<TransactionType[]>(
        (acc, { value, selected }) => {
          if (selected) {
            acc.push(value);
          }

          return acc;
        },
        []
      );

      const receiptIds = this.receptIdFromSearch
        ? [this.receptIdFromSearch]
        : undefined;

      const textQuery = this.searchStr.trim() || undefined;

      const sort = this.filter.sort;
      const limit = this.itemsPerPage;
      const offset = (this.filter.page - 1) * limit;
      const periodStart = this.filter.periodStart + getTimezoneHoursInSeconds();
      const periodEnd = this.filter.periodEnd + getTimezoneHoursInSeconds();

      const { items: transactions, total: totalTransactions } =
        await API.transaction.fetchTransactions(
          receiptIds?.length
            ? {
                receiptIds,
                periodEnd,
                periodStart,
              }
            : {
                sort,
                statuses,
                currencies,
                types,
                offset,
                limit,
                receiptIds,
                textQuery,
                periodStart,
                periodEnd,
                cardIds: this.filter.cardIds,
                userEmails: this.userEmails,
                cardGroupIds: this.filter.cardGroupIds,
              }
        );

      this.totalTransactions = totalTransactions;
      this.loadingTransactions = false;

      nestedFreeze(transactions);

      if (!this.usedInfiniteTransactionsList || this.filter.page === 1) {
        this.transactions = transactions;
      } else {
        this.transactions.push(...transactions);
      }

      const hasChanges = [
        {
          oldValue: limit,
          newValue: this.itemsPerPage,
        },
        {
          oldValue: offset,
          newValue: (this.filter.page - 1) * this.itemsPerPage,
        },
        {
          oldValue: textQuery,
          newValue: this.searchStr.trim() || undefined,
        },
        {
          oldValue: sort.join("-"),
          newValue: this.filter.sort.join("-"),
        },
      ].some(({ oldValue, newValue }) => oldValue !== newValue);

      if (hasChanges) {
        this.fetchTransactions();
      }
    } finally {
      this.loadingTransactions = false;
    }
  }

  private initWatcherTransactions() {
    this.$watch(
      () => {
        return [this.userEmails.length, this.cardsList.length].join("-");
      },
      () => {
        this.includeCardIds = this.userEmails.length
          ? this.cardsList
              .filter(({ owner: { email } }) => this.userEmails.includes(email))
              .map(({ id }) => id)
          : this.cardsList.map(({ id }) => id);

        const filterCardIds = this.userEmails.length
          ? this.filter.cardIds.filter((cardId) =>
              this.includeCardIds.includes(cardId)
            )
          : this.includeCardIds.concat([]);

        this.filter.cardIds =
          filterCardIds.length > 0
            ? filterCardIds
            : this.includeCardIds.concat([]);
      },
      {
        immediate: true,
      }
    );

    const fetchTransactionsBySearchText = () => {
      this.transactions = [];
      this.filter.page = 1;
      this.filter.forceUpdateKey++;
    };

    const onChangeSearchText = debounce(fetchTransactionsBySearchText, 400);

    this.$watch(
      () => {
        return this.receptIdFromSearch;
      },
      () => {
        onChangeSearchText.cancel();
        fetchTransactionsBySearchText();
      }
    );

    this.$watch(() => {
      return this.searchStr.trim();
    }, onChangeSearchText);

    this.$watch(
      () => {
        return [
          this.filter.forceUpdateKey,
          this.filter.page,
          this.itemsPerPage,
          this.filter.sort.join("_"),
        ].join("-");
      },
      () => {
        if (!this.filter.periodStart || !this.filter.periodEnd) {
          return;
        }

        this.filter.cardIds = !this.filter.cardIds.length
          ? this.includeCardIds.concat([])
          : this.filter.cardIds;

        this.filter.cardGroupIds = !this.filter.cardGroupIds.length
          ? this.cardGroupIds.concat([])
          : this.filter.cardGroupIds;

        onChangeSearchText.cancel();

        this.fetchTransactions();
      },
      {
        immediate: true,
      }
    );

    this.$once("hook:beforeDestroy", () => {
      onChangeSearchText.cancel();
    });
  }

  private async initWebPreferences() {
    await this.fetchUserWebPreferencesAction({
      key: UserWebPreferencesKey.TRANSACTIONS,
    });

    const { value } = this.userWebPreferencesGetter({
      key: UserWebPreferencesKey.TRANSACTIONS,
    });

    const userWebPreferences = value as Partial<{
      topBarTableHeaderValue: TableHeaderValue;
      topBarTableFilter: Partial<TopBarTableFilter>;
      filter: Partial<Filter>;
      itemsPerPage: number;
    }> | null;

    this.filter.itemsPerPage =
      userWebPreferences?.itemsPerPage ?? this.filter.itemsPerPage;
    this.topBarTableHeaderValue = this.topBarTableHeaderValue.map(
      ({ columnName, text, selected }) =>
        Object.freeze({
          columnName,
          text,
          selected:
            userWebPreferences?.topBarTableHeaderValue?.find(
              (item) => item.columnName === columnName
            )?.selected ?? selected,
        })
    );

    this.topBarTableFilter = Object.entries(
      this.topBarTableFilter
    ).reduce<TopBarTableFilter>((acc, [fieldName_, fieldValue_]) => {
      const fieldName = fieldName_ as keyof TopBarTableFilter;
      const fieldValue = fieldValue_ as TopBarFilterItems<string>;

      const fieldValueFromStorage = (
        userWebPreferences?.topBarTableFilter
          ? userWebPreferences.topBarTableFilter[fieldName]
          : undefined
      ) as TopBarFilterItems<string> | undefined;
      return {
        ...acc,
        [fieldName]: fieldValue.map(({ value, selected }) =>
          Object.freeze({
            value,
            selected:
              fieldValueFromStorage?.find((item) => item?.value === value)
                ?.selected ?? selected,
          })
        ),
      };
    }, this.topBarTableFilter);

    const filterCardIdFromQuery = +this.$route.query.filterCardId;
    const filterCardOwnerEmail = this.$route.query.filterCardOwnerEmail;

    if (
      Number.isInteger(filterCardIdFromQuery) &&
      typeof filterCardOwnerEmail === "string"
    ) {
      this.filter.cardIds = [filterCardIdFromQuery];

      if (this.isShowTeamMemberSelect) {
        this.filter.teamMembers.otherEmails = [filterCardOwnerEmail];
      } else {
        this.filter.teamMembers.teamleadEmails =
          this.teamleadMembersGetter.some(
            ({ email }) => email === filterCardOwnerEmail
          )
            ? [filterCardOwnerEmail]
            : this.filter.teamMembers.teamleadEmails;

        this.filter.teamMembers.mediabuyerEmails =
          this.mediabuyerMembersGetter.some(
            ({ email }) => email === filterCardOwnerEmail
          )
            ? [filterCardOwnerEmail]
            : this.filter.teamMembers.mediabuyerEmails;
      }
    } else {
      const teamleadOwnersFromStorage =
        userWebPreferences?.filter?.teamMembers?.teamleadEmails?.filter(
          (teamleadEmail) =>
            teamleadEmail === this.profileEmailGetter ||
            this.teamleadMembersGetter.some(
              ({ email }) => email === teamleadEmail
            )
        );

      this.filter.teamMembers.teamleadEmails = teamleadOwnersFromStorage?.length
        ? teamleadOwnersFromStorage
        : [];

      const mediabuyerOwnersFromStorage =
        userWebPreferences?.filter?.teamMembers?.mediabuyerEmails?.filter(
          (mediabuyerEmail) =>
            this.mediabuyerMembersGetter.some(
              ({ email }) => email === mediabuyerEmail
            )
        );

      this.filter.teamMembers.mediabuyerEmails =
        mediabuyerOwnersFromStorage?.length ? mediabuyerOwnersFromStorage : [];

      const otherOwnersFromStorage =
        userWebPreferences?.filter?.teamMembers?.otherEmails?.filter(
          (otherEmail) =>
            otherEmail === this.profileEmailGetter ||
            this.membersGetter.some(({ email }) => email === otherEmail)
        );

      this.filter.teamMembers.otherEmails = otherOwnersFromStorage?.length
        ? otherOwnersFromStorage
        : [];

      this.filter.cardIds = userWebPreferences?.filter?.cardIds?.length
        ? userWebPreferences.filter.cardIds
        : this.filter.cardIds;
    }

    if (this.userHasRoleGetter(Role.ROLE_TEAMLEAD)) {
      if (!this.filter.teamMembers.otherEmails.length) {
        this.filter.teamMembers.otherEmails.push(this.profileEmailGetter);
      }
    } else if (this.userHasRoleGetter(Role.ROLE_MEDIABUYER)) {
      if (!this.filter.teamMembers.mediabuyerEmails.length) {
        this.filter.teamMembers.mediabuyerEmails.push(this.profileEmailGetter);
      }
    } else {
      if (
        !this.filter.teamMembers.teamleadEmails.length &&
        !this.filter.teamMembers.mediabuyerEmails.length
      ) {
        this.filter.teamMembers.teamleadEmails = this.teamleadMembersGetter
          .map(({ email }) => email)
          .concat([this.profileEmailGetter]);
        this.filter.teamMembers.mediabuyerEmails =
          this.mediabuyerMembersGetter.map(({ email }) => email);
      }
    }

    this.filter.cardGroupIds = userWebPreferences?.filter?.cardGroupIds?.length
      ? userWebPreferences.filter.cardGroupIds
      : this.filter.cardGroupIds;

    this.$watch(
      () => {
        return this.topBarTableFilter;
      },
      () => {
        this.forceUpdateUserWebPreferencesKey++;
      }
    );

    this.$watch(
      () => {
        return this.topBarTableHeaderValue;
      },
      () => {
        this.forceUpdateUserWebPreferencesKey++;
      }
    );

    this.$watch(
      () => {
        return [
          this.filter.itemsPerPage,
          this.filter.teamMembers.mediabuyerEmails.length,
          this.filter.teamMembers.otherEmails.length,
          this.filter.teamMembers.teamleadEmails.length,
          this.filter.cardIds,
          this.filter.cardGroupIds,
          this.forceUpdateUserWebPreferencesKey,
        ].join("-");
      },
      debounce(() => {
        const { teamMembers, cardIds, cardGroupIds } = this.filter;

        this.updateUserWebPreferencesAction({
          key: UserWebPreferencesKey.TRANSACTIONS,
          value: {
            filter: { teamMembers, cardIds, cardGroupIds },
            topBarTableFilter: this.topBarTableFilter,
            topBarTableHeaderValue: this.topBarTableHeaderValue,
            itemsPerPage: this.filter.itemsPerPage,
          },
        });
      }, 500)
    );
  }

  protected createdHook(): void {
    this.topBarTableHeaderValue = this.topBarTableHeaderItems.map(
      ({ value, text }) =>
        Object.freeze({
          columnName: value,
          text,
          selected: true,
        })
    );
  }

  protected mountedHook(): void {
    const { start: periodStart, end: periodEnd } = getPeriodDate(
      Period.LAST_MONTH
    );

    this.filter.periodStart = periodRangeStartTimestamp(periodStart);
    this.filter.periodEnd = periodRangeEndTimestamp(periodEnd);

    this.$watch(
      () => {
        return (
          this.awaitDeps.length === 0 && this.walletsCurrenciesGetter.length > 0
        );
      },
      async (isReady) => {
        if (!isReady) return;

        await this.fetchTeamMembersAction();

        this.topBarTableFilter.currency = this.walletsCurrenciesGetter.map(
          (value) =>
            Object.freeze({
              selected: true,
              value,
            })
        );

        await this.initWebPreferences();

        this.loadingTransactions = false;
        this.initWatcherTransactions();
      },
      {
        immediate: true,
      }
    );
  }
}
